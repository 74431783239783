import { get, post } from '@/utils/request'

export default {
  activeNotNullCompetitions: (query: any) => get(`/competition/active-notnull`, query),
  activeNotNullCompetitionsOrder: (query: any) => get(`/competition/active-notnull-order`, query),
  activeCompetitions: (query: any) => get(`/competition/active`, query),
  closedCompetitions: (query: any) => get(`/competition/closed`, query),
  activeCount: () => get(`/competition/active-count`, null),
  closedCount: () => get(`/competition/closed-count`, null),
  competitionByTipID: (query: any) => get(`/competition/competition-by-tipping`, query),
  competitionRound: (query: any) => get(`/competition-round/rounds`, query),

  activeAdminCompetitions: (query: any) => get(`/admin/competition/active-competitions-by-admin`, query),
  closedAdminCompetitions: (query: any) => get(`/admin/competition/closed-competitions-by-admin`, query),
  activeAdminCount: () => get(`/admin/competition/active-count-by-admin`, null),
  closedAdminCount: () => get(`/admin/competition/closed-count-by-admin`, null),
  updateStatus: (query: any) => post(`/admin/competition/update-status`, query),
  update: (query: any) => post(`/admin/competition/save`, query),
  allStatus: () => post(`/admin/competition/all-status`, null),
  create: (query: any) => post(`/admin/competition/create`, query),
}