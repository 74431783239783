<template>
  <div class="home">
    <!-- <el-row>
      <el-col :span="1"></el-col>
      <el-col :span="16">
        <el-card class="user-panel" body-style="padding: 0px;">
          <el-row v-if="!regestering">
            <el-col :span="9"><HomeSignPanel @closedEvent="handleClosedEvent"></HomeSignPanel></el-col>
            <el-col :span="15"><HomeLoginPanel></HomeLoginPanel></el-col>
          </el-row>
          <el-row v-else>
            <el-col :span="24"><RegisterPanel @closedEvent="handleClosedEvent" @completeEvent="handleCompleteEvent"></RegisterPanel></el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="5">
        <el-card class="user-panel" body-style="padding: 0px;">
          <TrendingCompetitionPanel></TrendingCompetitionPanel>
        </el-card>
      </el-col>
      <el-col :span="1"></el-col>
    </el-row> -->
    <HomeLoginPanel></HomeLoginPanel>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { ElNotification } from 'element-plus'
import { h } from 'vue'

import TrendingCompetitionPanel from '@/components/home/TrendingCompetitionPanel.vue'
import HomeSignPanel from '@/components/home/HomeSignPanel.vue'
import HomeLoginPanel from '@/components/home/HomeLoginPanel.vue'
import RegisterPanel from '@/components/home/register/RegisterPanel.vue'

const regestering = ref(false);

const handleClosedEvent = (value:boolean) => {
  // console.log('Received custom event from child:', value);
  regestering.value = value;
};

const handleCompleteEvent = (value:boolean) => {
  if (value) {
    ElNotification.success({
      title: 'Success',
      // message: h('h1', { style: 'color: #3DA9FC' }, 'Sign Up Successfully'),
      message: 'Sign Up Successfully',
      duration: 0,
    });
  }
  else {
    ElNotification({
      title: 'Error',
      // message: h('h1', { style: 'color: #3DA9FC' }, 'Sign Up Successfully'),
      message: 'Something Wrong during the sign up',
      duration: 0,
    });
  }
}

</script>
<style>
.el-notification__title {
  color: #094067 !important;
}
</style>

<style scoped>
.user-panel {
  margin-top: 40px;
  /* background-color: #fffffe; */
  /* border-radius: 5px; */
  height: 550px;
}
</style>