<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px - 32px - 30px)"  -->
  <el-table :data="data.value" stripe>
    <el-table-column prop="id" label="ID" />
    <el-table-column prop="season" label="Season" />
    <el-table-column prop="sportName" label="Sport" />
    <el-table-column prop="status" label="Status" />
    <el-table-column label="Start Date" >
      <template #default="scope">
        {{ getDate(scope.row.startDate) }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="Operations" width="200">
      <template #default="scope">
        <el-button class="operation-text" type="primary" size="small" @click="router.push(`/fixture-game/${scope.row.id}`)">Update</el-button>
        <!-- <el-button v-if="scope.row.status == 'active'" class="operation-text" type="primary" color="#ef4565" size="small" style="width: 60px;">Close</el-button>
        <el-button v-else class="operation-text" type="primary" size="small" style="width: 60px;">Active</el-button> -->
        <el-button class="operation-text" type="primary" color="#ef4565" size="small" @click="edit(scope.row)">Edit</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogEditVisible" title="Edit Fixture" width="900">
    <el-descriptions
      title="Fixture Info"
      direction="vertical"
      :column="4"
      :border="true"

    >
      <el-descriptions-item label="Status" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
        width="350"
      >
        <el-select
          v-model="updateForm.status"
          class="m-2"
          placeholder="Select"
          style="width: 240px; margin-right: 15px;"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-descriptions-item>

      <el-descriptions-item label="Season" :span="3"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-input-number v-model="updateForm.season" :precision="0" :min="1970" :max="2100" :step="1" controls-position="right" />
      </el-descriptions-item>

      <el-descriptions-item label="Start Date" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-date-picker
          v-model="updateForm.startDate"
          type="date"
          placeholder="Pick a day"
        />
      </el-descriptions-item>

    </el-descriptions>

    <el-row style="margin-top: 15px;">
      <el-button class="operation-text" style="font-size: 16px;" type="primary" color="#ef4565" @click="save" >Update</el-button>
      
    </el-row>
    
  </el-dialog>

</template>

<script lang="ts" setup>
import { defineProps, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import fixtureApi from '@/api/fixture'

const router = useRouter();

const data = defineProps(['value']);
const emits = defineEmits(['updateEvent']);

const dialogEditVisible = ref(false);
const updateForm:any = ref({
  spid:1
});
const options = ref([
  'INACTIVE',
  'ACTIVE'
]);

const edit = (fixture:any) => {
  // updateForm.value = { ...updateForm.value, ...fixture };
  updateForm.value = { ...fixture };
  switch (fixture.status) {
  case 'inactive':updateForm.value.status = 0;break;
  case 'active':updateForm.value.status = 1;break;
  default:break;
  }
  switch (fixture.sportName) {
  case 'AFL':updateForm.value.spid = 1;break;
  default:break;
  }
  dialogEditVisible.value = true;
}

const save = () => {
  updateForm.value.startDate = new Date(updateForm.value.startDate).toISOString();
  fixtureApi.update(updateForm.value).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: "Update Success",
      });
      emits('updateEvent');
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}


const getDate = (date:string) => {
  return date.split('T')[0];
}
const formatMoney = (money:string) => {
  return money.replace('$', '$ ');
}
</script>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #fffffe;
}
</style>