<template>
    <el-menu
        router
        :default-active="route.path"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#094067"
        text-color="#fffffe"
        active-text-color="#eebbc3"
        :ellipsis="false"
        @select="handleSelect"
        style="height: 80px;"
    >
      <el-menu-item @click="backToHome">
        <!-- <img
          style="width: 100px"
          src="/images/element-plus-logo.svg"
          alt="Element logo"
        /> -->
        <h1 style="margin: 0 0 0 80px;">POWER TIPPING</h1>
      </el-menu-item>
      <div class="flex-grow" />
      <!-- <el-menu-item index="/about">About</el-menu-item>
      <el-menu-item index="/contact">Contact</el-menu-item> -->
      <el-menu-item index="/current-competition" style="margin-right: 80px;">Dashboard</el-menu-item>
    </el-menu>
</template>
  
<script lang="ts" setup>
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router';

  const route = useRoute();
  const router = useRouter();
  const handleSelect = (key: string, keyPath: string[]) => {}

  const backToHome = () => {
    router.push('/home');
  }
</script>
  
<style scoped>
  h1 {
    color:#fffffe;
    font-size: 30px;
    font-weight: 700;
  }
  h2 {
    color:#fffffe;
    font-size: 18px;
    font-weight: 500;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .el-menu-item {
    background-color: transparent !important;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  .el-menu-item.is-active {
    background-color: transparent !important;
    font-weight: 500;
  }
  .el-menu-item:hover{
      background-color: transparent !important;
      color: #eebbc3 !important;
      /* text-decoration: underline; */
  }
  .flex-grow {
    flex-grow: 1;
  }
</style>
  