<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px - 32px - 30px)"  -->
  <el-table :data="data.value" stripe>
    <el-table-column prop="round" label="Round" />
    <el-table-column prop="status" label="Status" />
    <el-table-column label="Lock Date" >
      <template #default="scope">
        {{ getDate(scope.row.lockDate) }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="Operations" width="200">
      <template #default="scope">
        <el-button class="operation-text" type="primary" color="#ef4565" size="small" @click="edit(scope.row.status, scope.row.lockDate, scope.row.id)">Edit</el-button>
        <el-button class="operation-text" type="primary" color="#ef4565" size="small" @click="lockStatus(scope.row.lockDate, scope.row.id)">Lock</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogDetailVisible" title="Edit Competition Round" width="800">
    <el-descriptions
      title="Round Info"
      direction="vertical"
      :column="4"
      :border="true"
    >
      <el-descriptions-item label="Status" 
        width="300px"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-select
          v-model="value"
          class="m-2"
          placeholder="Select"
          style="width: 240px; margin-right: 15px;"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-descriptions-item>
      
      <el-descriptions-item label="Lock Date" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-date-picker
          v-model="lock"
          type="date"
          placeholder="Pick a day"
        />
      </el-descriptions-item>
    </el-descriptions>

    <el-row style="margin-top: 15px;">
      <el-button class="operation-text" style="font-size: 16px;" type="primary" color="#ef4565"  @click="save">Save</el-button>
      
    </el-row>
    
  </el-dialog>

</template>

<script lang="ts" setup>
import { defineProps, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import competitionRoundApi from '@/api/competitionRound'


const data = defineProps(['value']);
const emits = defineEmits(['updateEvent']);

const dialogDetailVisible = ref(false);

const value = ref();
const options = ref([
  'ACTIVE',
  'LOCKED'
]);
const lock = ref();
const roundId = ref();

const edit = (status:string, lockDate:string, id:number) => {
  value.value = status == 'active' ? 0 : 1;
  lock.value = new Date(lockDate);
  dialogDetailVisible.value = true;
  roundId.value = id;
}

const lockStatus = (lockDate:string, id:number) => {
  competitionRoundApi.update({id:id,status:1,lockDate:new Date(lockDate).toISOString()}).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: "Update Success",
      });
      emits('updateEvent');
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const save = () => {
  competitionRoundApi.update({id:roundId.value,status:value.value,lockDate:new Date(lock.value).toISOString()}).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: "Update Success",
      });
      emits('updateEvent');
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}


const getDate = (date:string) => {
  return date.split('T')[0];
}
const formatMoney = (money:string) => {
  return money.replace('$', '$ ');
}
</script>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #fffffe;
}
</style>