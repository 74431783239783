<template>
  <div class="panel">
    <div class="panel-header flex">
      <el-icon @click="init"><RefreshRight /></el-icon>
      <div class="panel-search flex">
        <el-input class="panel-input" v-model="input" />
        <el-icon><Search /></el-icon>
      </div>
    </div>
    <div class="panel-header flex" style="margin-top: 15px;">
      <div class="flex" style="align-items:center;">
        <h1 class="header-title flex">
          Current Status
        </h1>
        <el-select
          v-model="value"
          class="m-2"
          placeholder="Select"
          style="width: 240px; margin-right: 15px;"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
        <el-button class="operation-text" type="primary" size="small" @click="update">Confirm</el-button>
        <el-button class="operation-text" color="#ef4565" type="primary" size="small" @click="lockAll">Lock All</el-button>
      </div>
    </div>
    <div class="panel-content" v-loading="loading">
      <ManageTable :value="tableData" @updateEvent="handleUpdate"></ManageTable>
    </div>
    <div class="panel-footer flex">
      <div style="flex:1"/>
      <el-pagination
        v-model:current-page="currentPage"
        layout="prev, pager, next" 
        :background="true" 
        :total="totalCount" 
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      />
      <div style="flex:1"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, defineProps, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import ManageTable from '@/components/competition/manage/ManageTable.vue'

import competitionRoundApi from '@/api/competitionRound'
import competitionApi from '@/api/competition'

const route = useRoute();

const cid = route.params.competition;

const input = ref();
const loading = ref(false);
const totalCount = ref(0);
const currentPage = ref(1);

const value = ref();
const options = ref([
]);

const tableData:any = ref([
]);

const handleCurrentChange = (page: number) => {
  loading.value = true;

  competitionRoundApi.competitionRound({cid:cid,page:page-1,size:10}).then(res => {
    if (res && res.code == 1) {
      tableData.value = res.response.competitionRounds;
      options.value = res.response.compStatus;
      value.value = res.response.index;
    }
    loading.value = false;
  }).catch(err => {
    loading.value = false;
  });
}

const init = async (page:number) => {
  // currentPage.value = 1;
  loading.value = true;

  competitionRoundApi.count({cid:cid}).then(res => {
    if (res && res.code == 1) {
      totalCount.value = res.response;
    }
    // loading.value = false;
  }).catch(err => {
    // loading.value = false;
  });

  competitionRoundApi.competitionRound({cid:cid,page:page-1,size:10}).then(res => {
    if (res && res.code == 1) {
      tableData.value = res.response.competitionRounds;
      options.value = res.response.compStatus;
      value.value = res.response.index;
    }
    loading.value = false;
  }).catch(err => {
    loading.value = false;
  });

}

const update = () => {
  competitionApi.updateStatus({cid:cid,status:value.value}).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: "Update Success",
      });
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const lockAll = () => {
  competitionRoundApi.lockAll({cid:cid}).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: "Update Success",
      });
      init(currentPage.value);
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const handleUpdate = () => {
  init(currentPage.value);
}

onBeforeMount( async () => {
  init(1);
});

</script>

<style scoped>
.header-title {
  height: 60px;
  /* padding: 0 20px; */
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  margin:0 15px 0 0;
  color: #094067;
}
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #fffffe;
}
.panel-footer {
  margin-top: 30px;
}
.panel-content {
  margin-top: 30px;
}
.panel-search {
  width: 250px;
  justify-content: space-between;
}
.panel-input {
  height: 35px;
  width: 200px;
}
.panel-header {
  padding: 0 15px;
  font-size: 25px;
  height: 50px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #fffffe;
  align-items: center;
  justify-content: space-between;
}
.panel {
  /* height: calc(100vh - 100px - 30px - 60px); */
  padding: 30px;
  border-radius: 5px;
  background-color: #fffffe;
}
.el-icon {
  background-color: #3DA9FC;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  cursor: pointer;
}
</style>