<template>
  <div class="box-background img-background">
    <div class="flex" v-if="login">
      <div style="flex: 1;" />
      <div class="box">
        <el-row class="header-background">
          <div style="flex: 1;" />
          <h1>LOGGED IN</h1>
          <div style="flex: 1;" />
        </el-row>
        <el-row class="main-background">
          <div v-loading="loading" style="width: 100%;">
            <h2 class="welcome-title flex">{{ `Hi ${username}! Welcome back!` }}</h2>
            <h2 class="welcome-content-text flex">Let's check our current status!</h2>
            <h2 class="welcome-content-text flex">Move to dashboard to manage the backstage</h2>
            <div class="btn-container flex" style="margin-top: 83px;">
              <el-button type="primary" class="dashboard-btn" color="#3DA9FC" @click="router.push('/current-competition')">DASHBOARD</el-button>
              <el-button 
              type="primary" 
              class="dashboard-btn" 
              color="#90B4CE" 
              @click="logout"
              style="width: 120px;"
              >LOGOUT</el-button>
            </div>
          </div>
        </el-row>
      </div>
      <div style="flex: 1;" />
    </div>
    <div class="flex" v-else>
      <div style="flex: 1;" />
      <div class="box">
          <el-row class="header-background">
              <div style="flex: 1;" />
              <h1>ADMIN LOGIN</h1>
              <div style="flex: 1;" />
          </el-row>
          <el-row class="main-background" v-loading="blinkLoading">
            <LoginForm @loginEvent="handleLogin"></LoginForm>
          </el-row>
      </div>
      <div style="flex: 1;" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import LoginForm from '@/components/home/LoginForm.vue'
import userApi from '@/api/user'

const router = useRouter();

const login = ref(false);
const loading = ref(false);
const blinkLoading = ref(true);
const username = ref('');

const logout = () => {
  loading.value = true;

  userApi.logout().then(res => {
    if (res && res.code == 1) {
      loading.value = false;
      login.value = false;
    }
  }).catch(err => {
    loading.value = false;
  })
}

const handleLogin = (user:any) => {
  login.value = true;
  username.value = user.username;
}
// store.commit('setUser', res.data.response);

onBeforeMount( async () => {
  // setTimeout(() => {}, 300);
  userApi.adminValidate().then(res => {
    if (res && res.code == 1) {
      login.value = true;
      blinkLoading.value = false;
      username.value = res.response.username;
    }
    else {
      login.value = false;
      blinkLoading.value = false;
    }
  }).catch(err => {                      
    login.value = false;
    blinkLoading.value = false;
    // router.push('home');
  });
});


</script>

<style scoped>
[v-cloak] {
  display: none;
}
h1 {
  color:#094067;
  font-size: 26px;
  font-weight: 600;
}
.btn-container {
  justify-content: space-between;
}
.dashboard-btn {
  width: 150px;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
}
.dashboard-btn:hover {
  color: #fffffe;
}
.welcome-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 0 30px 0;
  text-align: left;
}
.welcome-content-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  margin: 0 0 5px 0;
  text-align: left;
}
.main-background {
  padding: 50px 63px;
  height: 370px;
}
.header-background {
  height: 80px;
  border-bottom: 2px solid #D9D9D9;
  box-sizing: border-box;
}
.box {
  background-color: #fffffe;
  border-top: 5px solid #094067;
  border-radius: 5px;
  height: 450px;
  width: 600px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
}
.box-background {
  padding: 50px 0;
  margin: 0;
  /* background-color: #fffffe;
  border-radius: 0 5px 5px 0;
  height: calc(100vh - 80px); */
}
.img-background {
  /* background-image: url('@/assets/background.jpg');
  background-size: cover !important;
  background-position: center; */
}
</style>