<template>
  <div class="tipping-panel-container">
    <CompetitionPanel :value="type"></CompetitionPanel>
  </div>
  <div style="height: 30px;"></div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import CompetitionPanel from '@/components/competition/CompetitionPanel.vue'

const type = ref('current');
</script>

<style scoped>
.tipping-panel-container {
  margin-top: 15px;
  width: calc(100vw - 330px - 30px);
}
</style>