import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CurrentCompetitionView from '../views/CurrentCompetitionView.vue'
import HistoryCompetitionView from '../views/HistoryCompetitionView.vue'
import CompetitionManageView from '../views/CompetitionManageView.vue'
import CompetitionResultView from '../views/CompetitionResultView.vue'
import CompetitionCreateView from '../views/CompetitionCreateView.vue'
import FixtureView from '../views/FixtureView.vue'
import FxitureGameView from '../views/FxitureGameView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/current-competition',
    name: 'Current Comp',
    component: CurrentCompetitionView
  },
  {
    path: '/archive',
    name: 'Comp Archive',
    component: HistoryCompetitionView
  },
  {
    path: '/manage/:competition',
    name: 'Comp Manage',
    component: CompetitionManageView
  },
  {
    path: '/comp-result/:competition',
    name: 'Comp Result',
    component: CompetitionResultView
  },
  {
    path: '/comp-create',
    name: 'Comp Create',
    component: CompetitionCreateView
  },
  {
    path: '/fixture',
    name: 'Manage Fixture',
    component: FixtureView
  },
  {
    path: '/fixture-game/:fixture',
    name: 'Fixture Games',
    component: FxitureGameView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
