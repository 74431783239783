<template>
  <div class="form-content-container" v-loading="loading">
    <el-form 
      label-position="left"
      :model="form"
      :rules="rules"
      ref="registerFormRef"
    >
      <h1 class="form-header flex" style="margin: 0 0 10px 5px; font-weight: 300;">Charges</h1>
      <el-row class="join-form-row input-group flex">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="Entry Fee" label-width="100px" prop="entryFee">
            $
            <el-input-number v-model="form.entryFee" :precision="2" :min="5" :step="1" controls-position="right" style="margin-left: 10px;"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="System Fee:" label-width="100px" prop="systemFee">
            <el-input-number v-model="form.systemFee" :precision="2" :min="2" :max="5" :step="0.5" controls-position="right" style="margin-left: 10px;"/>
            &nbsp;&nbsp;%
          </el-form-item>
        </el-col>
      </el-row>
      
      <h1 class="form-header flex" style="margin: 0 0 10px 5px; font-weight: 300;">COMP INFO</h1>
      <el-row class="join-form-row input-group flex">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="Start Date:" label-width="100px" prop="startDate">
            <el-date-picker
              v-model="form.startDate"
              type="date"
              placeholder="Pick a day"
              style="margin-left: 10px;"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="Type:" label-width="100px" prop="type">
            <el-select
              v-model="form.type"
              class="m-2"
              placeholder="Select"
              style="width: 240px; margin-right: 15px;margin-left: 10px;"
            >
              <el-option
                v-for="(item, index) in types"
                :key="index"
                :label="item"
                :value="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="margin-top: 10px;">
          <el-form-item label="Name:" label-width="100px" prop="startDate">
            <el-input v-model="form.name" style="width: 240px;margin-left: 10px;" placeholder="Please input" />
          </el-form-item>
        </el-col>
      </el-row>

      <h1 class="form-header flex" style="margin: 0 0 10px 5px; font-weight: 300;">Prize Info</h1>
      <el-row class="join-form-row input-group flex">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="Amount:" label-width="100px" prop="amount">
            <el-input-number v-model="form.amount" :precision="0" :step="1" :min="1" :max="5" controls-position="right" style="margin-left: 10px;"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item label="Gap:" label-width="100px" prop="gap">
            <el-input-number v-model="form.gap" :precision="0" :step="1" :min="1" :max="20" controls-position="right" style="margin-left: 10px;"/>
          </el-form-item>
        </el-col>
      </el-row>
      
    </el-form>
  </div>
  <div class="form-footer-container flex">
    <!-- <el-button type="primary" class="submit-btn" color="#3DA9FC" >PREV</el-button> -->
    <el-button type="primary" class="submit-btn" @click="submit(registerFormRef)" :disabled="loading">CREATE</el-button>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'

import competitioApi from '@/api/competition'

const registerFormRef = ref<FormInstance>();

const loading = ref(false);

// const emits = defineEmits(['createEvent']);

const data = defineProps(['value']);

const form:any = ref({
  entryFee: 0,
  systemFee: 0,
  startDate: new Date(),
  type:0,
  amount:3,
  gap:1,
  name:''
});
// form.value = { ...form.value, ...data.value };
const types = ref([
  'WEEKLY',
  'HALF_SEASONALLY',
  'SEASONALLY'
]);

const validateNotNull = (rule: any, value: any, callback: any) => {
  if (value == null) {
    return callback(new Error('Cannot leave this as blank'));
  }
  else {
    callback();
  }
}

const rules = reactive<FormRules<typeof form>>({
  entryFee: [{ validator: validateNotNull, trigger: 'blur' }],
  systemFee: [{ validator: validateNotNull, trigger: 'blur' }],
  startDate: [{ validator: validateNotNull, trigger: 'blur' }],
  type: [{ validator: validateNotNull, trigger: 'blur' }],
  amount: [{ validator: validateNotNull, trigger: 'blur' }],
  gap: [{ validator: validateNotNull, trigger: 'blur' }],
});

const submit = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate(valid => {
    if (valid) {
      loading.value = true;
      competitioApi.create({
        season:data.value.season,
        entryFee:form.value.entryFee,
        systemFee:form.value.systemFee,
        startDate:new Date(form.value.startDate).toISOString(),
        spid:data.value.spid,
        type:form.value.type,
        prizeGap:form.value.gap,
        prizeAmount:form.value.amount,
        name:form.value.name
      }).then(res => {
        if (res && res.code == 1) {
          ElMessage({
            type: 'success',
            message: "Create Success",
          });
        }
        loading.value = false;
      }).catch(err => {
        ElMessage({
          type: 'error',
          message: err,
        });
        loading.value = false;
      });
    }
    else {

    }
  });
}

onBeforeMount(() => {

});

</script>

<style scoped>
.el-form-item {
  margin-bottom: 0;
}
.form-footer-container {
  padding: 10px 15px;
  border-top: 2px solid #D9D9D9;
  justify-content: space-between;
}
.input-group {
  /* box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.05); */
  /* background-color: rgba(144, 180, 206, 0.15); */
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 10px 15px;
}
.join-form-row {
  justify-content: space-between;
}
.form-address-input {
  height: 35px;
  width: calc(((100vw - 450px) / 4 * 3 + 60px - 30px * 4 - 100px * 2 - 60px - 12px * 3) / 3);
}
.form-input {
  height: 35px;
  width: calc(((100vw - 450px) / 4 * 3 + 60px - 30px * 3 - 100px * 2 - 12px * 2) / 2);
}
.form-content-container {
  padding: 30px 30px;
}
.form-header {
  color: #5F6C7B;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.submit-btn {
  width: 120px;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
}
</style>

