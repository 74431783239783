<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px - 32px - 30px)"  -->
  <el-table :data="data.value" stripe>
    <el-table-column prop="id" label="Id" width="100"/>
    <!-- <el-table-column prop="sportName" label="Sport"/> -->
    <el-table-column prop="season" label="Season" width="100" />
    <el-table-column prop="username" label="Nick Name" />
    <el-table-column prop="email" label="Email" width="200"/>
    <el-table-column prop="rank" label="Rank" />
    <el-table-column prop="score" label="Total Score" />
    <!-- <el-table-column prop="status" label="Status" /> -->
    
    <el-table-column fixed="right" label="Operations" width="250">
      <template #default="scope">
        <el-button class="operation-text" type="primary" size="small" @click="detail(scope.row.id, scope.row.uid)">Detail</el-button>
        <el-button class="operation-text" type="primary" size="small" @click="score(scope.row.id)">Scores</el-button>
        <el-button class="operation-text" type="primary" size="small" @click="round(scope.row.id)">Rounds</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogDetailVisible" title="Punter Detail" width="800">
    <el-descriptions
      title="User Info"
      direction="vertical"
      :column="4"
      :border="true"
      v-loading="detailLoading"
    >
      <el-descriptions-item label="ID" :span="1"
        width="200"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ id }}
      </el-descriptions-item>
      <el-descriptions-item label="Age" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.age }}
      </el-descriptions-item>
      <el-descriptions-item label="Gender" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.gender }}
      </el-descriptions-item>

      <el-descriptions-item label="Contact" :span="1"
        width="200"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.phone }}
      </el-descriptions-item>
      <el-descriptions-item label="Mail" :span="3"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.email }}
      </el-descriptions-item>

      <el-descriptions-item label="Address" :span="4"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.address }}
      </el-descriptions-item>

      <el-descriptions-item label="City" :span="1"
        width="200"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.city }}
      </el-descriptions-item>
      <el-descriptions-item label="State" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.state }}
      </el-descriptions-item>
      <el-descriptions-item label="Postcode" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.competitionUserInfo.postcode }}
      </el-descriptions-item>

    </el-descriptions>

    <el-descriptions
      title="Order Info"
      direction="vertical"
      style="margin-top: 15px;"
      :column="4"
      :border="true"
      v-loading="detailLoading"
    >
      <el-descriptions-item label="ID" :span="1"
        width="200"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.order.oid }}
      </el-descriptions-item>
      <el-descriptions-item label="Order Status" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.order.status }}
      </el-descriptions-item>
      <el-descriptions-item label="Payment Status" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ detailInfo?.order.paymentStatus }}
      </el-descriptions-item>

      <el-descriptions-item label="Creation Time" :span="1"
        width="200"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ getDate(detailInfo?.order?.timestamp || '') }}
      </el-descriptions-item>
      <el-descriptions-item label="Expire Time" :span="3"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ getDate(detailInfo?.order?.expiration || '') }}
      </el-descriptions-item>

      <el-descriptions-item label="Paid Time" :span="3"
        label-class-name="my-label" 
        class-name="my-content"
      >{{ getDate(detailInfo?.order?.paidTime || '') }}
      </el-descriptions-item>

    </el-descriptions>
  </el-dialog>

  <el-dialog v-model="dialogScoreVisible" title="Scores" width="800">
    <el-table :data="tableData" stripe v-loading="loading">
      <el-table-column prop="round" label="Round" width="80"/>
      <el-table-column label="Date" width="110">
        <template #default="scope">
          {{ getDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column prop="teamA.name" label="Team A" />
      <el-table-column prop="teamB.name" label="Team B" />
      <el-table-column prop="result.name" label="Selection" />
      <el-table-column prop="score" label="Score" />
    </el-table>
    <div class="score-footer flex">
      <div style="flex:1"/>
      <el-pagination
        v-model:current-page="currentPage"
        layout="prev, pager, next" 
        :background="true" 
        :total="totalCount" 
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      />
      <div style="flex:1"/>
    </div>
  </el-dialog>

  <el-dialog v-model="dialogRoundVisible" title="Round Scores" width="800">
    <el-table :data="roundData" stripe v-loading="roundLoading">
      <el-table-column prop="round" label="Round" />
      <el-table-column prop="score" label="Score" />
    </el-table>
    <div class="score-footer flex">
      <div style="flex:1"/>
      <el-pagination
        v-model:current-page="roundPage"
        layout="prev, pager, next" 
        :background="true" 
        :total="roundCount" 
        @current-change="handleRoundChange"
        :hide-on-single-page="true"
      />
      <div style="flex:1"/>
    </div>
  </el-dialog>

</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import competitionRoundApi from '@/api/competitionRound'
import tippingApi from '@/api/tipping'

const data = defineProps(['value']);
// const emits = defineEmits(['updateEvent']);

const dialogDetailVisible = ref(false);
const dialogScoreVisible = ref(false);
const dialogRoundVisible = ref(false);

const detailInfo = ref();
const id = ref();
const tippingId = ref();

const detailLoading = ref(false);
const loading = ref(false);
const totalCount = ref(0);
const currentPage = ref(1);
const tableData = ref();

const roundLoading = ref(false);
const roundCount = ref(0);
const roundPage = ref(1);
const roundData = ref([]);

const detail = (cuid:number, uid:number) => {
  detailLoading.value = true;
  tippingApi.detail({cuid:cuid, uid:uid}).then(res => {
    if (res && res.code == 1) {
      detailInfo.value = res.response;
      id.value = uid;
    }
    detailLoading.value = false;
  }).catch(err => {
    detailLoading.value = false;
  });
  dialogDetailVisible.value = true;
}

const handleCurrentChange = (page: number) => {
  loading.value = true;
  tippingApi.selection({cuid:tippingId.value,page:page-1,size:10}).then(res => {
    if (res && res.code == 1) {
      tableData.value = res.response;
      
    }
    loading.value = false;
  }).catch(err => {
    loading.value = false;
  });
}

const score = (cuid:number) => {
  loading.value = true;
  tippingId.value = cuid;
  tippingApi.selectionCount({cuid:cuid}).then(res => {
    if (res && res.code == 1) {
      totalCount.value = res.response;
    }
  }).catch(err => {

  });
  tippingApi.selection({cuid:cuid,page:0,size:10}).then(res => {
    if (res && res.code == 1) {
      tableData.value = res.response;
      
    }
    loading.value = false;
  }).catch(err => {
    loading.value = false;
  });
  dialogScoreVisible.value = true;
}

const handleRoundChange = (page: number) => {
  roundLoading.value = true;
  tippingApi.score({cuid:tippingId.value,page:page-1,size:10}).then(res => {
    if (res && res.code == 1) {
      roundData.value = res.response;
      
    }
    roundLoading.value = false;
  }).catch(err => {
    roundLoading.value = false;
  });
}

const round = (cuid:number) => {
  roundLoading.value = true;
  tippingId.value = cuid;
  tippingApi.scoreCount({cuid:cuid}).then(res => {
    if (res && res.code == 1) {
      roundCount.value = res.response;
    }
  }).catch(err => {

  });
  tippingApi.score({cuid:cuid,page:0,size:10}).then(res => {
    if (res && res.code == 1) {
      roundData.value = res.response;
    }
    roundLoading.value = false;
  }).catch(err => {
    roundLoading.value = false;
  });
  dialogRoundVisible.value = true;
}


const getDate = (date:string) => {
  return date.replace('T', ' • ');
}
const formatMoney = (money:string) => {
  return money.replace('$', '$ ');
}

</script>

<style scoped>
.score-footer {
  margin-top: 30px;
}
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #fffffe;
}
</style>