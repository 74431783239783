<template>
  <el-container class="layout-container-demo">

    <el-aside v-if="!isHorizontalMenu()" width="330px" style="padding: 30px; height: 100vh;">
      <MenuVertical></MenuVertical>
    </el-aside>
    
    <el-container style="height: 100vh;">
      <el-header v-if="isHorizontalMenu()" style="height: 80px;">
        <Menu></Menu>
      </el-header>

      <el-header v-else style="height: 85px; padding-top: 30px;">
        <MenuTabPanel></MenuTabPanel>
      </el-header>

      <el-main>
        <el-scrollbar ref="scrollbarRef">
          <RouterView></RouterView>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="ts" setup>
import { watch, provide, createApp, onMounted, onBeforeMount, ref } from 'vue'
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router'
import { ElScrollbar } from 'element-plus'

import Menu from '@/components/menu/Menu.vue'
import MenuVertical from '@/components/menu/MenuVertical.vue'
import MenuTabPanel from '@/components/menu/MenuTabPanel.vue'

const route = useRoute();

// axios.defaults.baseURL = '/api';

// provide('axios', axios);

const isHorizontalMenu = () => {
  let routeLink = route.path;
  let menuArr = ["/home", "/about", "/contact", "/admin/home", "/admin"]
  if (menuArr.includes(routeLink)) {
    return true;
  }
  else {
    return false;
  }
}

const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>();
watch(
  () => route.path,
  () => {
    resetScrollbar();
  }
);

const resetScrollbar = () => {
  if (scrollbarRef.value) {
    // Reset the scrollbar's scroll position to the top
    scrollbarRef.value.setScrollTop(0);
  }
};

const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300);
body {
  color: #094067;
  background: #f5f5f5;
  font-family: Lato, sans-serif;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
}

span {
  /* color: #fffffe; */
  font-family: Lato, sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #094067;
  font-family: Lato, sans-serif;
  font-weight: 600;
}

.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
</style>

<style scoped>
.layout-container-demo .el-header {
    padding: 0;
}
.layout-container-demo .el-main {
    padding: 0;
}
.tab-background {
  height: 80px;
  padding-top: 30px;
}
</style>

<style lang="scss">
#app {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #094067;
}
</style>
