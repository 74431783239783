import { get, post } from '@/utils/request'

export default {
  activeTipping: (query: any) => get(`/tipping/active`, query),
  closedTipping: (query: any) => get(`/tipping/closed`, query),
  activeCount: () => get(`/tipping/active-count`, null),
  closedCount: () => get(`/tipping/closed-count`, null),
  usernameCheck: (query: any) => get(`/tipping/username-check`, query),
  save: (query: any) => post(`/tipping/save`, query),

  tippingByComp: (query: any) => get(`/admin/tipping/users-by-comp`, query),
  countByComp: (query: any) => get(`/admin/tipping/count-by-comp`, query),
  detail: (query: any) => get(`/admin/tipping/detail-by-tipping`, query),
  score: (query: any) => get(`/admin/tipping/scores-by-tipping`, query),
  scoreCount: (query: any) => get(`/admin/tipping/scores-count`, query),
  selection: (query: any) => get(`/admin/tipping/selections-by-tipping`, query),
  selectionCount: (query: any) => get(`/admin/tipping/selections-count`, query),
}