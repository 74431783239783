<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px - 32px - 30px)"  -->
  <el-table :data="data.value" stripe>
    <el-table-column prop="round" label="Round" width="80"/>
    <el-table-column prop="venue" label="Venue" />
    <el-table-column label="Date" width="120">
      <template #default="scope">
        {{ getDate(scope.row.date) }}
      </template>
    </el-table-column>
    <!-- <el-table-column prop="status" label="Status" /> -->
    <el-table-column label="Game Results">
      <el-table-column prop="teamA.name" label="Team A" />
      <el-table-column prop="teamAResult" label="Team A Result" />
      <el-table-column prop="teamB.name" label="Team B" />
      <el-table-column prop="teamBResult" label="Team B Result" />
    </el-table-column>

    <el-table-column fixed="right" label="Operations" width="100">
      <template #default="scope">
        <el-button class="operation-text" type="primary" color="#ef4565" size="small" @click="edit(scope.row)">Edit</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogDetailVisible" title="Edit Game" width="800">
    <el-descriptions
      title="Game Info"
      direction="vertical"
      :column="4"
      :border="true"
    >
      <el-descriptions-item label="Venue" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
        width="350"
      >
        <el-input v-model="updateForm.venue" placeholder="Please input" />
      </el-descriptions-item>
      
      <el-descriptions-item label="Date" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-date-picker
          v-model="updateForm.date"
          type="datetime"
          placeholder="Pick a Date"
          format="YYYY-MM-DD HH:mm:ss"
          date-format="MMM DD, YYYY"
          time-format="HH:mm"
        />
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      title="Game Results"
      direction="vertical"
      :column="4"
      :border="true"
      style="margin-top: 15px;"
    >
      <el-descriptions-item label="Team A" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
        width="300"
      >
        <el-select
          v-model="updateForm.teamA.id"
          class="m-2"
          placeholder="Select"
          style="width: 240px; margin-right: 15px;"
        >
          <el-option
            v-for="(item, index) in teams"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-descriptions-item>
      
      <el-descriptions-item label="Team A Result" :span="3"
        label-class-name="my-label" 
        class-name="my-content"
      >
      <el-input-number v-model="updateForm.teamAResult" :precision="0" :step="1" controls-position="right" style="margin-left: 10px;"/>
      </el-descriptions-item>

      <el-descriptions-item label="Team B" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
        width="350"
      >
        <el-select
          v-model="updateForm.teamB.id"
          class="m-2"
          placeholder="Select"
          style="width: 240px; margin-right: 15px;"
        >
          <el-option
            v-for="(item, index) in teams"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-descriptions-item>
      
      <el-descriptions-item label="Team B Result" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >
      <el-input-number v-model="updateForm.teamBResult" :precision="0" :step="1" controls-position="right" style="margin-left: 10px;"/>
      </el-descriptions-item>
    </el-descriptions>

    <el-row style="margin-top: 15px;">
      <el-button class="operation-text" style="font-size: 16px;" type="primary" color="#ef4565"  @click="save">Save</el-button>
    </el-row>
    
  </el-dialog>

</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import gameApi from '@/api/game'
import teamApi from '@/api/team'

const router = useRouter();

const data = defineProps(['value']);
const emits = defineEmits(['updateEvent']);

const dialogDetailVisible = ref(false);

const updateForm = ref();

const teams:any = ref([
]);

const edit = (games:any) => {
  // updateForm.value = { ...updateForm.value, ...games };
  updateForm.value = { ...games };
  // console.log(updateForm.value);

  dialogDetailVisible.value = true;
}

const save = () => {
  updateForm.value.date = new Date(updateForm.value.date).toISOString();
  gameApi.update(updateForm.value).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: "Update Success",
      });
      emits('updateEvent');
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const getDate = (date:string) => {
  return date.replace('T', ' • ').slice(0, -3);
}

onBeforeMount(() => {
  teamApi.teams().then(res => {
    if (res && res.code == 1) {
      teams.value = res.response;
    }
  }).catch(err => {

  });
});
</script>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #fffffe;
}
</style>