<template>
  <div class="login-form-container" v-loading="loading">
    <el-form 
      label-position="left"
      :model="form"
      label-width="120px"
      class="login-form"
      ref="registerFormRef"
      :rules="rules"
    >
      <el-form-item label="Email" prop="username">
        <el-input v-model="form.username" />
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input v-model="form.password" show-password/>
      </el-form-item>
      <el-row style="margin-top: 40px;">
        <el-switch v-model="form.remember" style="--el-switch-on-color: #3DA9FC;" />
        <h5>Remember Me</h5>
      </el-row>
      <el-row style="margin-top: 40px;">
        <el-button type="primary" class="login-btn" @click="onSubmit(registerFormRef)">LOGIN</el-button>
      </el-row>
        
    </el-form>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import loginApi from '@/api/login'
import userApi from '@/api/user'

const emits = defineEmits(['loginEvent']);
const loading = ref(false);

const form = reactive({
  username: '',
  password: '',
  remember: true
});

const registerFormRef = ref<FormInstance>();

const validateEmail= (rule: any, value: any, callback: any) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) {
    callback(new Error('Please input the email'));
  }
  else if (!emailRegex.test(value)) {
    callback(new Error('Please use correct email format'));
  }
  else {
    callback()
  }
}

const validatePassword = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('Please input the password'));
  }
  else {
    callback()
  }
}

const rules = reactive<FormRules<typeof form>>({
  username: [{ validator: validateEmail, trigger: 'blur' }],
  password: [{ validator: validatePassword, trigger: 'blur' }],
});



const login = () => {
  loading.value = true;

  loginApi.login(form)
    .then(handleLoginResponse)
    .catch(handleLoginError);
}

const handleLoginResponse = (res:any) => {
  if (res && res.code == 1) {
    userApi.adminValidate()
      .then(handleAdminValidation)
      .catch(handleAdminValidationError);
  } else if (res.code == 402) {
    ElMessage({
      type: 'error',
      message: res.message,
    });
  }
  loading.value = false;
}

const handleLoginError = (err:any) => {
  ElMessage({
    type: 'error',
    message: err,
  });
  loading.value = false;
}

const handleAdminValidation = (res:any) => {
  if (res && res.code == 1) {
    emits('loginEvent', { username: res.response.username });
  }
}

const handleAdminValidationError = (err:any) => {
  userApi.logout();
  ElMessage({
    type: 'error',
    message: 'Access Denied',
  });
}

const onSubmit = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate(valid => {
    if (valid) {
      login();
    }
  });
}
</script>

<style>
.login-form-container {
  width: 100%;
}
.login-form-container .el-form-item__label {
  font-size: 20px;
  font-weight: 500;
  color: #094067;
}
</style>

<style scoped>
h5 {
  margin: 0 0 0 15px;
  line-height: 32px;
  font-weight: 400;
  font-size: 16px;
  color: #094067;
}
.login-btn {
  width: 120px;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
}
.login-btn:hover {
  color: #fffffe;
}
.login-form {
  width: 100%;
}
</style>