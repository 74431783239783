<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px - 32px - 30px)"  -->
  <el-table :data="data.value" stripe>
    <el-table-column prop="cid" label="ID" width="80"/>
    <el-table-column prop="sport.sportName" label="Sport" width="80"/>
    <el-table-column prop="season" label="Season" width="80"/>
    <!-- <el-table-column prop="admin.username" label="Admin"/> -->
    <el-table-column prop="entryFee" label="Entry Fee" width="120">
      <template #default="scope">
        {{ formatMoney(scope.row.entryFee) }}
      </template>
    </el-table-column>
    <!-- <el-table-column prop="prizePool" label="Prize Pool"/> -->
    <el-table-column label="System Fee" width="120">
      <template #default="scope">
        {{ scope.row.systemFee + " %" }}
      </template>
    </el-table-column>
    <el-table-column prop="status" label="Status"/>
    <el-table-column prop="type" label="Type" width="120"/>
    <el-table-column label="Lock Date" width="120">
      <template #default="scope">
        {{ getDate(scope.row.startDate) }}
      </template>
    </el-table-column>
    <el-table-column prop="punterNumber" label="Punters"/>
    <el-table-column fixed="right" label="Operations" width="200">
      <template #default="scope">
        <el-button class="operation-text" type="primary" size="small" @click="router.push(`/comp-result/${scope.row.cid}`)">Punters</el-button>
        <!-- <el-button class="operation-text" type="primary" color="#3DA9FC" size="small" 
        @click="detail(scope.row.admin.uid, scope.row.punterNumber, scope.row.prizePool)">Edit</el-button> -->
      </template>
    </el-table-column>
  </el-table>

</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import competitionApi from '@/api/competition'

const route = useRoute();
const router = useRouter();

const data = defineProps(['value']);
const emits = defineEmits(['updateEvent']);

const getDate = (date:string) => {
  return date.split('T')[0];
}
const formatMoney = (money:string) => {
  return money.replace('$', '$ ');
}
</script>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #fffffe;
}
</style>