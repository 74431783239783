<template>
  <div class="panel-header flex">
    <div class="header-left flex">
      <h1 class="header-title header-container flex">
        <div>Create New Competition</div>
        <div class="link-text flex">
          rules
          <el-icon style="font-size: 12px;"><Right /></el-icon>
        </div>
      </h1>
 
      <!-- <el-affix :offset="100"> -->
      <div class="left-bottom flex">
        <h1 class="lock-text flex">
          <div style="flex:1;"/>
            <div v-if="beforeDate(new Date(fixture?.startDate))">
              This Season is starting in <span style="font-weight: 600;">&nbsp{{ daysBetweenNow(new Date(fixture?.startDate)) }}&nbsp</span> days.
            </div>
            <div v-else>
              This Season is already starting <span style="font-weight: 600;">&nbsp{{ daysBetweenNow(new Date(fixture?.startDate)) }}&nbsp</span> days.
            </div>
          <div style="flex:1;"/>
        </h1>
      </div>
      <!-- </el-affix> -->
      
    </div>
    <div class="header-right">
      <h1 class="header-title flex">
        <div class="title-text flex">
          Fixture {{ fixture?.season }}
        </div>
        <div class="link-text flex">
          details
          <el-icon style="font-size: 12px;"><Right /></el-icon>
        </div>
      </h1>
      <div class="header-right-text flex">
        This fixture is updated by our master administrator
      </div>
    </div>
  </div>
  <div class="panel-content">
    <div class="join-form">
      <div class="form-header-container flex">
        <h1 class="form-header flex">
          Fixture: {{ fixture?.season }}
        </h1>
      </div>
      <CreateForm :value="fixture"></CreateForm>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { watch, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { daysBetweenNow, beforeDate } from '@/utils/date'
import { ElMessage } from 'element-plus'

import CreateForm from '@/components/competition/create/CreateForm.vue'

import fixtureApi from '@/api/fixture'

const route = useRoute();
const router = useRouter();

const fixture:any = ref({
  startDate: new Date()
});


const noFixError = () => {
  router.push('current-competition')
  ElMessage({
    type: 'error',
    message: 'No Fixture Available',
  });
}

onBeforeMount(() => {
  fixtureApi.activeFixture().then(res => {
    if (res && res.code == 1) {
      if (res.response == null) {
        noFixError();
      }
      fixture.value = res.response;
    }
  }).catch(err => {
    noFixError();
  });
});

</script>

<style>
.join-form-row .el-form-item__label {
  font-size: 16px;
  font-weight: 500;
  color: #094067;
}
/* .is-process .el-step__icon {
  color: #094067 !important;
  border-color: #094067 !important;
  border: 2px solid !important;
} */
.el-step__icon-inner {
  font-weight: 500 !important;
}
.is-process {
  color: #094067 !important;
  border-color: #094067 !important;
  font-weight: 500 !important;
}
.is-success {
  color: #3DA9FC !important;
  border-color: #3DA9FC !important;
}
</style>

<style scoped>
/* .el-form-item {
  margin-bottom: 0;
}
.form-footer-container {
  padding: 10px 15px;
  border-top: 2px solid #D9D9D9;
  justify-content: space-between;
} */
.steps {
  margin-bottom: 15px;
}
/* .input-group {
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 10px 15px;
}
.join-form-row {
  justify-content: space-between;
}
.form-address-input {
  height: 35px;
  width: calc(((100vw - 450px) / 4 * 3 + 60px - 30px * 4 - 100px * 2 - 60px - 12px * 3) / 3);
}
.form-input {
  height: 35px;
  width: calc(((100vw - 450px) / 4 * 3 + 60px - 30px * 3 - 100px * 2 - 12px * 2) / 2);
}
.form-input-text {
  margin: 0 0 15px 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
}
.form-content-container {
  padding: 30px 30px;
} */
.form-header {
  color: #5F6C7B;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.form-header-container {
  padding: 7.5px 15px;
  height: 20px;
  border-bottom: 2px solid #D9D9D9;
  justify-content: space-between;
}
.join-form {
  /* height: 300px; */
  background-color: #fffffe;
  border-radius: 5px;
  /* margin-bottom: 30px; */
}
/* .submit-btn {
  width: 120px;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
} */
.panel-content {
  margin-top: 30px;
}
.header-right-text {
  padding: 0 20px;
  height: 55px;
  text-align: left;
  font-size: 14px;
  align-items: center;
}
/* .shift-btn {
  width: 80px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
} */
.left-bottom {
  height: 40px;
  justify-content: space-between;
}
.lock-text {
  border-radius: 5px;
  background-color: rgba(144, 180, 206, 0.15);
  width: 520px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #5F6C7B;
  margin: 0;
  align-items: center;
}
.header-right {
  width: calc(100% - 520px - 30px);
  border-radius: 5px;
  background-color: rgba(144, 180, 206, 0.15);
}
.header-left {
  flex-direction: column;
  justify-content: space-between;
  width: 520px;
}
.header-title {
  height: 60px;
  padding: 0 20px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  margin:0;
  color: #094067;
}
.header-container {
  background-color: rgba(216, 238, 254, 0.5);
  border-radius: 5px;
}
.link-text {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #5F6C7B;
  align-items: flex-end;
  user-select: none;
  height: 20px;
  cursor: pointer;
}
.title-text {
  align-items: flex-end;
  height: 20px;
}
.panel-header {
  justify-content: space-between;
  width:100%;
  height: 115px;
}
</style>