<template>
  <div>
    <el-menu router :default-active="route.path" class="menu-vertical" background-color="#fffffe" text-color="#094067"
      active-text-color="#3DA9FC" @open="handleOpen" @close="handleClose"
      :default-openeds="openeds">
      <h1 class="header" @click="backToHome">POWER TIPPING</h1>
      <el-scrollbar class="scroll">
        <!-- <el-menu-item index="/dashboard">
          <el-icon>
            <House />
          </el-icon>
          Dashboard
        </el-menu-item> -->
        <el-sub-menu index="/competition">
          <template #title>
            <el-icon>
              <Soccer />
            </el-icon>
            Competition
          </template>
          <el-menu-item index="/current-competition">
            <div class="sub-menu-margin">
              Current Competition
            </div>
          </el-menu-item>
          <el-menu-item index="/archive">
            <div class="sub-menu-margin">
              History Competition
            </div>
          </el-menu-item>
          <el-menu-item index="/comp-create">
            <div class="sub-menu-margin">
              Create
            </div>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="/tipping" v-if="isMaster">
          <template #title>
              <el-icon><CollectionTag /></el-icon>
              Fixture
          </template>
          <el-menu-item index="/fixture">
              <div class="sub-menu-margin">
                  Manage Fixture
              </div>
          </el-menu-item>
          <!-- <el-menu-item index="/history-tipping">
              <div class="sub-menu-margin">
                  History Tipping
              </div>
          </el-menu-item> -->
        </el-sub-menu>
        <!-- <el-menu-item index="/ladder">
          <el-icon>
            <Medal />
          </el-icon>
          My Ladder
        </el-menu-item> -->
        <!-- <el-menu-item index="/achivement">
            <el-icon><TrophyBase /></el-icon>
            Achivement
        </el-menu-item> -->
        <!-- <el-sub-menu index="/order-management">
          <template #title>
            <el-icon>
              <ShoppingCart />
            </el-icon>
            Order Management
          </template>
          <el-menu-item index="/pending-order">
            <div class="sub-menu-margin">
              Pending Order
            </div>
          </el-menu-item>
          <el-menu-item index="/complete-order">
            <div class="sub-menu-margin">
              Complete Order
            </div>
          </el-menu-item>
        </el-sub-menu> -->
        <!-- <el-menu-item index="/order">
            <el-icon><ShoppingCart /></el-icon>
            Order Management
        </el-menu-item> -->
        <!-- <el-menu-item index="/setting">
            <el-icon><setting /></el-icon>
            Setting
        </el-menu-item> -->
      </el-scrollbar>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import userApi from '@/api/user'

const route = useRoute();
const router = useRouter();

const isMaster = ref(false);
const openeds = ['/competition', '/tipping'];

const backToHome = () => {
  router.push('/home');
}
const handleOpen = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath)
}
const handleClose = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath)
}

onBeforeMount(() => {
  userApi.masterValidate().then(res => {
    if (res && res.code == 1) {
      isMaster.value = true;
    }
  }).catch(err => {
    isMaster.value = false;
  });
});
</script>

<style>
.el-sub-menu .el-sub-menu__title {
  font-size: 16px;
  font-weight: 500;
  user-select: none;
}

.el-sub-menu .el-sub-menu__title:hover {
  background-color: rgba(61, 169, 252, 0.5) !important;
  color: #fffffe !important;
}
</style>

<style scoped>
h1 {
  margin: 0 0 0 20px;
  color: #094067;
  font-size: 26px;
  font-weight: 600;
  text-align: left;
}

/* h3 {
  color:#094067;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
} */
.sub-menu-margin {
  margin-left: 20px;
}

.el-menu-item {
  font-size: 16px;
  font-weight: 500;
  user-select: none;
}

.el-menu-item:hover {
  background-color: rgba(61, 169, 252, 0.5) !important;
  color: #fffffe !important;
}

.menu-vertical {
  height: calc(100vh - 60px);
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
}

.header {
  height: 45px;
  cursor: pointer;
  user-select: none;
}

.scroll {
  height: calc(100vh - 105px);
}
</style>