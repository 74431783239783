<template>
  <div class="panel">
    <div class="panel-header flex">
      <el-icon @click="init"><RefreshRight /></el-icon>
      <div class="panel-search flex">
        <el-input class="panel-input" v-model="input" />
        <el-icon><Search /></el-icon>
      </div>
    </div>
    <div class="panel-content" v-loading="loading">
      <CurrentTable v-if="data.value==='current'" :value="tableData" @updateEvent="handleUpdate"></CurrentTable>
      <HistoryTable v-else-if="data.value==='history'" :value="tableData"></HistoryTable>
    </div>
    <div class="panel-footer flex">
      <div style="flex:1"/>
      <el-pagination v-if="data.value==='current'" 
      v-model:current-page="currentPage"
      layout="prev, pager, next" 
      :background="true" 
      :total="totalCount" 
      @current-change="handleCurrentChange"
      :hide-on-single-page="true"
      />
      <el-pagination v-else-if="data.value==='history'"
      v-model:current-page="currentPage" 
      layout="prev, pager, next" 
      :background="true" 
      :total="totalCount" 
      @current-change="handleCurrentChange"
      :hide-on-single-page="true"
      />
      <div style="flex:1"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, defineProps, ref } from 'vue'

import CurrentTable from '@/components/competition/CurrentTable.vue'
import HistoryTable from '@/components/competition/HistoryTable.vue'

import competitionApi from '@/api/competition'

const data = defineProps(['value']);

const input = ref();
const loading = ref(false);
const totalCount = ref(0);
const currentPage = ref(1);

const tableData:any = ref([
]);

const handleCurrentChange = (page: number) => {
  loading.value = true;
  if (data.value==='current') {

    competitionApi.activeAdminCompetitions({page:page-1,size:10}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
  else {

    competitionApi.closedAdminCompetitions({page:page-1,size:10}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
}

const init = async (page:number) => {
  // currentPage.value = 1;
  loading.value = true;
  if (data.value==='current') {

    competitionApi.activeAdminCount().then(res => {
      if (res && res.code == 1) {
        totalCount.value = res.response;
      }
      // loading.value = false;
    }).catch(err => {
      // loading.value = false;
    });

    competitionApi.activeAdminCompetitions({page:page-1,size:10}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
  else {

    competitionApi.closedAdminCount().then(res => {
      if (res && res.code == 1) {
        totalCount.value = res.response;
      }
      // loading.value = false;
    }).catch(err => {
      // loading.value = false;
    });

    competitionApi.closedAdminCompetitions({page:0,size:10}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
}

const handleUpdate = () => {
  init(currentPage.value);
}

onBeforeMount( async () => {
  init(1);
});

</script>

<style scoped>
.panel-footer {
  margin-top: 30px;
}
.panel-content {
  margin-top: 30px;
}
.panel-search {
  width: 250px;
  justify-content: space-between;
}
.panel-input {
  height: 35px;
  width: 200px;
}
.panel-header {
  padding: 0 15px;
  font-size: 25px;
  height: 50px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #fffffe;
  align-items: center;
  justify-content: space-between;
}
.panel {
  /* height: calc(100vh - 100px - 30px - 60px); */
  padding: 30px;
  border-radius: 5px;
  background-color: #fffffe;
}
.el-icon {
  background-color: #3DA9FC;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  cursor: pointer;
}
</style>