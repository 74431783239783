<template>
  <div class="tab-panel">
    <el-scrollbar ref="sRef">
      <div class="flex" ref="innerRef">
        <div v-for="(r, index) in routes" :key="index" >
          <div class="menu-tab" :class="{active : r.active}">
            <div class="tab-text" @click="redirect(r.path)">{{ r.name }}</div>
            <el-icon v-if="r.del" color="#094067" @click="deleteTab(r.name)"><CloseBold/></el-icon>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import { watch, nextTick, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElScrollbar } from 'element-plus'


const sRef = ref();
const innerRef = ref();

const router = useRouter();
const redirect = (path: string) => {
  router.push(path);
}

const route = useRoute();
const routes: { name: string; path: string; active: boolean; del: boolean }[] = reactive([
]);

const curRoute = {
  name: (route.name as string) || "",
  path: route.path,
  active: true,
  del: false
}

if (curRoute.name !== "") {
  routes.push(curRoute);
}

const curTab = ref((route.name as string) || "");
watch(
  () => route.path,
  () => {
    updateRoutes();
    setScrollToRight();
    // console.log(routes);
    // console.log(curTab.value);
  }
);

const deleteTab = (tab: string) => {
  let i = routes.findIndex(r => r.name === tab);
  routes.splice(i, 1);

  setDelBtn();

  if (tab == curTab.value) {
    let len = routes.length;
    redirect(routes[len-1].path);
  }
  
}

const setScrollToRight = async () => {
  await nextTick();
  // const max = innerRef.value!.clientWidth;

  let name = (route.name as string) || "";

  if (name !== null) {
    let nxtIndex = routes.findIndex(r => r.name === name);

    if (nxtIndex != -1) {
      let len = nxtIndex * 140;
      sRef.value!.setScrollLeft(len);
    }
  }
}

const setDelBtn = () => {
  let len = routes.length;
  if (len > 1) {
    routes.forEach(r => {
      r.del = true;
    });
  }
  else {
    routes.forEach(r => {
      r.del = false;
    });
  }
}

const updateRoutes = () => {
  let nxtRoute = {
    name: (route.name as string) || "",
    path: route.path,
    active: true,
    del: false
  }

  let curIndex = routes.findIndex(r => r.name === curTab.value);
  let nxtIndex = routes.findIndex(r => r.name === nxtRoute.name);

  if (nxtRoute.name !== "") {
    if (curIndex != -1) {
      let cur = routes[curIndex];
      cur.active = false;
    }

    curTab.value = nxtRoute.name;

    if (nxtIndex == -1) {
      routes.push(nxtRoute);
    }
    else {
      let nxt = routes[nxtIndex];
      nxt.path = nxtRoute.path;
      nxt.active = true;
    }
  }

  setDelBtn();
}    
</script>

<style scoped>
.tab-panel {
  width: 800px;
  height: 55px;
  margin-bottom: 15px;
}
</style>

<style scoped>
h1 {
  font-size: 16px;
  font-weight: 600;
  color: #094067;
  margin: 0;
  text-align: center;
  line-height: 40px;
}
.tab-text {
  font-size: 14px;
  font-weight: 600;
  color: #094067;
  margin: 0;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}
.el-icon {
  font-Size: 12px;
  vertical-align:middle;
  margin-left: 5px;
  margin-bottom: 1px;
  cursor: pointer;
}
.el-icon:hover {
  background-color: #094067;
  border-radius: 2px;
  color: #fffffe;
}
.menu-tab {
  height: 40px;
  width: 140px;
  margin-right: 5px;
}
.active {
  background-color: #fffffe;
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
</style>