<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px - 32px - 30px)"  -->
  <el-table :data="data.value" stripe>
    <el-table-column prop="cid" label="ID" width="80"/>
    <el-table-column prop="sport.sportName" label="Sport" width="80"/>
    <el-table-column prop="season" label="Season" width="80"/>
    <!-- <el-table-column prop="admin.username" label="Admin"/> -->
    <el-table-column prop="entryFee" label="Entry Fee" width="100">
      <template #default="scope">
        {{ formatMoney(scope.row.entryFee) }}
      </template>
    </el-table-column>
    <!-- <el-table-column prop="prizePool" label="Prize Pool"/> -->
    <el-table-column label="System Fee" width="100">
      <template #default="scope">
        {{ scope.row.systemFee + " %" }}
      </template>
    </el-table-column>
    <el-table-column prop="status" label="Status"/>
    <el-table-column prop="type" label="Type"/>
    <el-table-column label="Lock Date" width="120">
      <template #default="scope">
        {{ getDate(scope.row.startDate) }}
      </template>
    </el-table-column>
    <el-table-column prop="punterNumber" label="Punters"/>
    <el-table-column fixed="right" label="Operations" width="250">
      <template #default="scope">
        <el-button class="operation-text" type="primary" size="small" @click="router.push(`/manage/${scope.row.cid}`)">Manage</el-button>
        <el-button class="operation-text" type="primary" color="#ef4565" size="small" :disabled="scope.row.status == 'locked'" 
          @click="edit(scope.row)">Edit</el-button>
        <el-button class="operation-text" type="primary" size="small" @click="router.push(`/comp-result/${scope.row.cid}`)">Punters</el-button>
        <!-- <el-button class="operation-text" type="primary" color="#3DA9FC" size="small" 
        @click="detail(scope.row.admin.uid, scope.row.punterNumber, scope.row.prizePool)">Edit</el-button> -->
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogEditVisible" title="Edit Competition" width="900">
    <el-descriptions
      title="Competition Info"
      direction="vertical"
      :column="4"
      :border="true"
      v-loading="loading"
    >
      <el-descriptions-item label="Status" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-select
          v-model="competition.status"
          class="m-2"
          placeholder="Select"
          style="width: 240px; margin-right: 15px;"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-descriptions-item>

      <el-descriptions-item label="Type" :span="2"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-select
          v-model="competition.type"
          class="m-2"
          placeholder="Select"
          style="width: 240px; margin-right: 15px;"
        >
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-descriptions-item>

      <el-descriptions-item label="Lock Date" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <el-date-picker
          v-model="competition.startDate"
          type="date"
          placeholder="Pick a day"
        />
      </el-descriptions-item>

      <el-descriptions-item label="Name" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
      >
      <el-input v-model="competition.name" placeholder="Please input" />
      </el-descriptions-item>

      <el-descriptions-item label="Entry Fee" :span="1"
        label-class-name="my-label" 
        class-name="my-content"
      >
        <!-- <el-input v-model="competition.entryFee" placeholder="Please input" 
          :formatter="(value:any) => `$ ${Number(value.replace('$', '')).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" 
          :parser="(value:any) => value.replace(/\$\s?|(,*)/g, '')"/> -->
          $
          <el-input-number v-model="competition.entryFee" :precision="2" :step="1" controls-position="right" style="margin-left: 10px;"/>
      </el-descriptions-item>

      <el-descriptions-item label="System Fee" :span="1"
        label-class-name="my-label"
        class-name="my-content"
      >
        <!-- <el-input v-model="competition.systemFee" placeholder="Please input" 
          :formatter="(value:any) => `% ${Number(value).toFixed(1)}`" 
          :parser="(value:any) => value.replace(/\%\s?|(,*)/g, '')"/> -->
          %
          <el-input-number v-model="competition.systemFee" :precision="1" :step="0.1" :max="5" controls-position="right" style="margin-left: 10px;"/>
      </el-descriptions-item>

    </el-descriptions>

    <el-row style="margin-top: 15px;">
      <el-button class="operation-text" style="font-size: 16px;" type="primary" color="#ef4565" @click="save" :disabled="loading">Update</el-button>
      
    </el-row>
    
  </el-dialog>

</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import competitionApi from '@/api/competition'

const route = useRoute();
const router = useRouter();

const data = defineProps(['value']);
const emits = defineEmits(['updateEvent']);

const loading = ref(false);
const competition:any = ref();
const options = ref([
]);
const types = ref([
  'WEEKLY',
  'HALF_SEASONALLY',
  'SEASONALLY'
]);

const dialogEditVisible = ref(false);

const edit = (comp:any) => {
  competition.value = { ...comp };
  switch (comp.status) {
  case 'active':competition.value.status = 0;break;
  case 'locked':competition.value.status = 1;break;
  case 'closed':competition.value.status = 2;break;
  case 'inactive':competition.value.status = 3;break;
  default:break;
  }
  // statusValue.value = comp.status == 'active' ? 0 : 1;
  switch (comp.type) {
  case 'weekly':competition.value.type = 0;break;
  case 'half-seasonally':competition.value.type = 1;break;
  case 'seasonally':competition.value.type = 2;break;
  default:break;
  }
  competition.value.entryFee = Number(competition.value.entryFee.replace('$', ''));
  competition.value.systemFee = Number(competition.value.systemFee);
  dialogEditVisible.value = true;
}

const save = () => {
  loading.value = true;
  competitionApi.update({
    cid:competition.value.cid,
    season:competition.value.season,
    entryFee:competition.value.entryFee,
    systemFee:competition.value.systemFee,
    status:competition.value.status,
    startDate:new Date(competition.value.startDate).toISOString(),
    spid:competition.value.sport.spid,
    type:competition.value.type,
    name:competition.value.name
  }).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: "Update Success",
      });
      emits('updateEvent');
    }
    loading.value = false;
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
    loading.value = false;
  });
}

const getDate = (date:string) => {
  return date.split('T')[0];
}
const formatMoney = (money:string) => {
  return money.replace('$', '$ ');
}

onBeforeMount(() => {
  competitionApi.allStatus().then(res => {
    if (res && res.code == 1) {
      options.value = res.response;
    }
  }).catch(err => {

  });
});
</script>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #fffffe;
}
</style>